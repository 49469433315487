<template>
    <div class="page_section" v-if="city.bySlug.id">
        <!--<div class="container">
            <h1>{{city.bySlug.title}}</h1>
            <p>Region: {{city.bySlug.region.title}}</p>
            <div v-html="city.bySlug.description"></div>
            <div class="row">
                <div class="col-6" v-for="(item, index) in city.bySlug.gallery" :key="index">
                    <img :src="assetsPath+item.image" width="100%" alt="">
                </div>
            </div>
            <h2>Content Elements</h2>
            <hr>
            <ContentElements v-for="(element, index) in city.bySlug.content_elements" :content="element" :key="index" />
        </div>-->
        <MobileTitle :title="city.bySlug.title" />
        <ContentElements v-for="(element, index) in city.bySlug.content_elements" :content="element" :key="index" />
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import ContentElements from "../_content_elements/ContentElements";
    import MobileTitle from "../_common/MobileTitle";

    export default {
        name: "City",
        components: {
            ContentElements,
            MobileTitle
        },
        computed: {
            ...mapState([
                'city',
            ]),
        },
        created() {
            if(this.$route.params.slug) {
                this.$store.dispatch('city/getBySlug', this.$route.params.slug).then(() => {}, (error) => {
                    if(error.response.status === 404) {
                        this.$router.replace({
                            name: '404', params: {
                                locale:  this.$route.params.locale ? this.$route.params.locale : null
                            }
                        });
                    }
                });
            }
        },
        beforeDestroy() {
            this.$store.commit('city/setBySlug', {});
        }
    }
</script>

<style scoped>

</style>
